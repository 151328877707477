import React from 'react';
import settings from '../../../settings';

import TeamMember from './teammember';

// Instructor's Images
const AdamSeaman = `${settings.IMAGES_BASE_URL}/images/teenschool/instructors/sml/Adam Seaman 1.jpg`;
const DylanArcher = `${settings.IMAGES_BASE_URL}/images/teenschool/instructors/sml/Dylan Archer 1.jpg`;
const JamesBrookshire = `${settings.IMAGES_BASE_URL}/images/teenschool/instructors/sml/James Brookshire 1.jpg`;
const RyanReeves = `${settings.IMAGES_BASE_URL}/images/teenschool/instructors/sml/Ryan Reeves 1.jpg`;
const ReidWilson = `${settings.IMAGES_BASE_URL}/images/teenschool/instructors/sml/Reid_Wilson_1.jpg`;
const DavidDaltonJr = `${settings.IMAGES_BASE_URL}/images/teenschool/instructors/sml/David Dalton Jr. 1.jpg`;
const WilliamSnyder = `${settings.IMAGES_BASE_URL}/images/teenschool/instructors/sml/William (Bill) Snyder 1.jpg`;
const RobStout = `${settings.IMAGES_BASE_URL}/images/teenschool/instructors/sml/Rob_Stout.jpg`;
const ChristopherHill = `${settings.IMAGES_BASE_URL}/images/teenschool/instructors/sml/Christopher_Hill.jpg`;
const DaveMcMillan = `${settings.IMAGES_BASE_URL}/images/teenschool/instructors/sml/Dave_Mcmillan.jpg`;
const BryanRandall = `${settings.IMAGES_BASE_URL}/images/teenschool/instructors/sml/Bryan_Randall.jpg`;
const DavidThilenius = `${settings.IMAGES_BASE_URL}/images/teenschool/instructors/sml/David_Thilenius.jpg`;
const AdamSeamanPopup = `${settings.IMAGES_BASE_URL}/images/teenschool/instructors/popup/Adam_Seaman_popUp.jpg`;
const AllisonBormannPopup = `${settings.IMAGES_BASE_URL}/images/teenschool/instructors/popup/Allison_Bormann_popUp.jpg`;
const AndyVanCleef = `${settings.IMAGES_BASE_URL}/images/teenschool/instructors/sml/Andy Van Cleef 1.jpg`;
const AndyVanCleefPopup = `${settings.IMAGES_BASE_URL}/images/teenschool/instructors/popup/Andy_van_Cleef_popUp.jpg`;
const ChristopherHillPopup = `${settings.IMAGES_BASE_URL}/images/teenschool/instructors/popup/Christopher_Hill_popUp.jpg`;
const ClintKimelPopup = `${settings.IMAGES_BASE_URL}/images/teenschool/instructors/popup/Clint_Kimel_popUp.jpg`;
const DerekLeonardPopup = `${settings.IMAGES_BASE_URL}/images/teenschool/instructors/popup/Derek_Leonard_popUp.jpg`;
const EvanYorkPopup = `${settings.IMAGES_BASE_URL}/images/teenschool/instructors/popup/Evan_York_popUp.jpg`;
const MattMullins = `${settings.IMAGES_BASE_URL}/images/teenschool/instructors/sml/Matt Mullins 1.jpg`;
const MattMullinsPopup = `${settings.IMAGES_BASE_URL}/images/teenschool/instructors/popup/Matt_Mullins_popUp.jpg`;
const MikeRenner = `${settings.IMAGES_BASE_URL}/images/teenschool/instructors/sml/Mike_Renner_1.jpg`;
const MikeRennerPopup = `${settings.IMAGES_BASE_URL}/images/teenschool/instructors/popup/Mike_Renner_popUp.jpg`;
const RafaelTorres = `${settings.IMAGES_BASE_URL}/images/teenschool/instructors/sml/Rafael Torres 1.jpg`;
const RafaelTorresPopup = `${settings.IMAGES_BASE_URL}/images/teenschool/instructors/popup/Rafael_Torres_popUp.jpg`;
const ColinFoley = `${settings.IMAGES_BASE_URL}/images/teenschool/instructors/sml/Colin-Fole.jpg`;
const DonnieIsley = `${settings.IMAGES_BASE_URL}/images/teenschool/instructors/sml/Donnie-Isley.jpg`;

const data = [
  {
    id: 1,
    name: 'Matt Mullins',
    title: 'Chief Driving Instructor',
    image: `${MattMullins}`,  
    popupimage: `${AllisonBormannPopup}`, 
    location: 'Carolina', 
    introduction:
      'A winning NASCAR, NASA and SCCA racer, Allison Bormann loved giving hot laps and drift rides at racetracks around the country. But for the past 14 years, she’s been an instructor at the BMW Performance Driving School. Small wonder her favorite exercise is the Skid Pad – “by far the most intimidating and challenging exercise we do. But once drivers get over the fear of being out of control, they love sliding around!” Her advice? “Most of the driving population doesn’t have essential, basic driving knowledge. Get proper training!”'
  },
  {
    id: 2,
    name: 'Adam Seaman',
    title: 'Lead Instructor',
    image: `${AdamSeaman}`,
    // popupimage: `${RickFairbanksPopup}`,  
    location: 'Carolina',
    introduction:
      'Rick Fairbanks understands drivers. He competed – and won – at Road America, Lime Rock, Trois Riviers and Phoenix. He extensively taught teenage drivers (which in itself is cause for a medal of honor). And for the past 11 years, Rick has been turning out classes of confident drivers here, especially enjoying teaching Skid Pad exercises. What does this winning race veteran have to say to those who want to better their skills? “Focus on the basics – seating position, eyes, focus and awareness.”'
  },
  {
    id: 3,
    name: 'Dylan Archer',
    image: `${DylanArcher}`,
    // popupimage: `${LauraHayesPopup}`,  
    location: 'Carolina',
    introduction:
      'When you start your driving career at 8, racking up track victories comes naturally. But Laura has no problem slowing down to help drivers of all abilities – from a race-pilot astronaut, to timid teens on the skid pad, to teaching M Performance fans how to drift. She loves watching her students leave with more confidence in their abilities, and advises, “When driving on a race track, smooth is fast!” And the best way to improve? “Practice – and seat time.”'
  },
  {
    id: 4,
    name: 'Allison Bormann',
    image: ``,
    popupimage: `${ChristopherHillPopup}`,  
    location: 'Carolina',
    introduction:
      'Christopher is the performance driver’s dream instructor. A veteran of over 18 years teaching racing and high-performance driving, he has earned multiple instructor certifications, including BMW in Germany. On TV, he’s appeared on American Hot Rod and was the runner-up on Season 3 of GT Academy. Now Christopher calls the BMW Performance Driving School home, encouraging students to think and push the limit, understanding how to get the most out of their car and leave with greater confidence in their ability.'
  },
  {
    id: 5,
    name: 'James Brookshire',
    image: `${JamesBrookshire}`,
    // popupimage: `${DonnieIsleyPopup}`,  
    location: 'Carolina',
    introduction:
      'When Donnie says, "Stay focused,” he puts his victories where his mouth is – with more than 50 SCCA National wins, and past class records in Formula Vee at Road Atlanta and Virginia International Raceway. Having conducted driver training for Homeland Security, the Secret Service and BMW in Latin America, what does this competitor do for fun? Just host international groups of racers who tackle Germany’s notorious Nürburgring.'
  },
  {
    id: 6,
    name: 'Andy van Cleef',
    image: `${AndyVanCleef}`,
    popupimage: `${ClintKimelPopup}`,  
    location: 'Carolina',
    introduction:
      'Clint Kimel has been a BMW Performance Driving School instructor since 2003, and was even featured in a New York Times article on teen-driver training. While his favorite exercise is drilling M School participants in Corner Entry techniques, a sense of humor comes in handy, whether dealing with overconfident students or family feuds. But at the end of the day, Clint finds satisfaction in having imparted to his students a greater sense of confidence behind the wheel and the desire to continually practice good driving skills.'
  },
  {
    id: 7,
    name: 'David Dalton Jr.',
    image: `${DavidDaltonJr}`,
    popupimage: `${DerekLeonardPopup}`,  
    location: 'Carolina',
    introduction:
      'Derek began driving at the tender age of 14 and never looked back. After many podium finishes in IMSA and SCCA, as well as instructing aspiring racers, he now trains students at the BMW Performance Center. His favorite student question: “Does the double clutch transmission have two clutch pedals?” The most important thing to keep in his students minds? “Always be thinking and seeing ahead of the car – and try for no surprises.”'
  },
  {
    id: 8,
    name: 'Colin Foley',
    image: `${ColinFoley}`,
    popupimage: `${MattMullinsPopup}`,  
    location: 'Carolina',
    introduction:
      'Starting his professional career at the late age of 23, Matt has competed in six major racing series; taught at race-driving schools; and tutored the rich, the famous and even NASCAR drivers. A SAG stunt driver, Matt’s talents also appear on-screen. Here, he loves teaching drifting around the skid pad. But where stunts are all about “skids, slides, jumps and precise moves,” Matt says, “Performance driving is about control, speed and just enjoying the car.”'
  },
  {
    id: 9,
    name: 'Donnie Isley',
    image: `${DonnieIsley}`,
    popupimage: `${MikeRennerPopup}`,  
    location: 'Carolina',
    introduction:
      'Professional driving instructor Mike Renner has competed on track, done film and photo shoots, and taught athletes, government officials and entertainers the finer points of piloting vehicles. He’s even been serenaded by a rock star while taking him for a hot lap. But it always comes back to basics for him – teaching students to be smooth with throttle input, using vision properly, understanding the cause and effect of driving dynamics, and his favorite topic: how to drift.'
  },
  {
    id: 10,
    name: 'Ryan Reeves',
    image: `${RyanReeves}`,
    popupimage: `${AdamSeamanPopup}`,  
    location: 'Carolina',
    introduction:
      'This BMW Internationally Certified Instructor has competed in Drift races and holds a Formula Drift Pro license. He also does stunt driving for NBC’s DRIVE, and loves coaching skidpad and Lead/Follow on the larger racetracks. His key to driving skill improvement? “Vision. Disciplining your vision is the most difficult thing to master – but also the most important.'
  },
  {
    id: 11,
    name: 'Mike Renner',
    image: `${MikeRenner}`,
    popupimage: `${RafaelTorresPopup}`,  
    location: 'Carolina',
    introduction:
      'Originally from Colombia, Rafael Torres started karting when he was 8. A two-time German Touring Series Champion in the U.S., he has multiple wins and lap records. Rafael is passionate about driver development, sharing his knowledge and technical expertise gained from building racecars and racing BMWs. He thrives on one-on-one instruction; one of his favorite exercises is the Skid Pad because it requires many different teaching methods. And he knows you’ll learn more if you’re having a good time. (In fact, he’ll be laughing along with you.)'
  },
  {
    id: 12,
    name: 'William Snyder',
    image: `${WilliamSnyder}`,
    popupimage: `${EvanYorkPopup}`,  
    location: 'Carolina',
    introduction:
      'What is the secret to winning? Take it from Evan York, who started racing go-karts when he was 6: “Breathe! Inexperienced drivers get caught up in the intensity of dynamic exercises and often forget to breathe!” What else? “Focus on where you want to go – not where your car is already going.” He likes teaching on skid pad: “Taking drivers who are uncomfortable with skid recovery, and teaching them to stay relaxed while maintaining a slide, is a rewarding task!”'
  },
  {
    id: 13,
    name: 'Rafael Torres',
    image: `${RafaelTorres}`,
    popupimage: `${AndyVanCleefPopup}`,  
    location: 'Carolina',
    introduction:
      'Engine builder, kart racer and crew chief, Andy van Cleef can build and race cars – but he really enjoys sharing his driving love with students. From Olympic medalists to nervous spouses, he helps give all a new respect and enthusiasm for driving. On the skid pad, his students are dialed in using all their senses, as well as harnessing their reflexes in tough situations to stay in control. Most important, every student comes away with a greater sense of driving responsibility.'
  },
  {
    id: 14,
    name: 'Reid Wilson',
    image: `${ReidWilson}`,
    popupimage: `${AndyVanCleefPopup}`,  
    location: 'Carolina',
    introduction:
      'Engine builder, kart racer and crew chief, Andy van Cleef can build and race cars – but he really enjoys sharing his driving love with students. From Olympic medalists to nervous spouses, he helps give all a new respect and enthusiasm for driving. On the skid pad, his students are dialed in using all their senses, as well as harnessing their reflexes in tough situations to stay in control. Most important, every student comes away with a greater sense of driving responsibility.'
  },
  {
    id: 15,
    name: 'Rob Stout',
    title: 'Lead Instructor',
    image: `${RobStout}`,
    popupimage: `${AndyVanCleefPopup}`,  
    location: 'California',
    introduction:
      'Engine builder, kart racer and crew chief, Andy van Cleef can build and race cars – but he really enjoys sharing his driving love with students. From Olympic medalists to nervous spouses, he helps give all a new respect and enthusiasm for driving. On the skid pad, his students are dialed in using all their senses, as well as harnessing their reflexes in tough situations to stay in control. Most important, every student comes away with a greater sense of driving responsibility.'
  },
  {
    id: 16,
    name: 'Christopher Hill',
    image: `${ChristopherHill}`,
    popupimage: `${AndyVanCleefPopup}`,  
    location: 'California',
    introduction:
      'Engine builder, kart racer and crew chief, Andy van Cleef can build and race cars – but he really enjoys sharing his driving love with students. From Olympic medalists to nervous spouses, he helps give all a new respect and enthusiasm for driving. On the skid pad, his students are dialed in using all their senses, as well as harnessing their reflexes in tough situations to stay in control. Most important, every student comes away with a greater sense of driving responsibility.'
  },
  {
    id: 17,
    name: 'Dave McMillan',
    image: `${DaveMcMillan}`,
    popupimage: `${AndyVanCleefPopup}`,  
    location: 'California',
    introduction:
      'Engine builder, kart racer and crew chief, Andy van Cleef can build and race cars – but he really enjoys sharing his driving love with students. From Olympic medalists to nervous spouses, he helps give all a new respect and enthusiasm for driving. On the skid pad, his students are dialed in using all their senses, as well as harnessing their reflexes in tough situations to stay in control. Most important, every student comes away with a greater sense of driving responsibility.'
  },
  {
    id: 18,
    name: 'Bryan Randall',
    image: `${BryanRandall}`,
    popupimage: `${AndyVanCleefPopup}`,  
    location: 'California',
    introduction:
      'Engine builder, kart racer and crew chief, Andy van Cleef can build and race cars – but he really enjoys sharing his driving love with students. From Olympic medalists to nervous spouses, he helps give all a new respect and enthusiasm for driving. On the skid pad, his students are dialed in using all their senses, as well as harnessing their reflexes in tough situations to stay in control. Most important, every student comes away with a greater sense of driving responsibility.'
  },
  {
    id: 19,
    name: 'David Thilenius',
    image: `${DavidThilenius}`,
    popupimage: `${AndyVanCleefPopup}`,  
    location: 'California',
    introduction:
      'Engine builder, kart racer and crew chief, Andy van Cleef can build and race cars – but he really enjoys sharing his driving love with students. From Olympic medalists to nervous spouses, he helps give all a new respect and enthusiasm for driving. On the skid pad, his students are dialed in using all their senses, as well as harnessing their reflexes in tough situations to stay in control. Most important, every student comes away with a greater sense of driving responsibility.'
  },
];

class Team extends React.Component {
  state = {
    show: false,
    teammembers: [],
    activeTeamMember: '',
    modalImage: '',
    modalTitle: '',
    modalDescription: '',
    selectedIndex: 0
  };
  componentDidMount() {
    this.setState({teammembers: data})
  }

  handleModalOpen = (selectedIndex) => {
    this.setState({
      show: true,
      activeTeamMember: selectedIndex,
      modalImage: this.state.teammembers[selectedIndex].popupimage,
      modalTitle: this.state.teammembers[selectedIndex].name,
      modalDescription: this.state.teammembers[selectedIndex].introduction
    })
  }

  handleModalClose = () => {
    this.setState({
      show: false,
      activeTeamMember: ''
    })
  }

  handleNextTeamMember = () => {

    let arr = this.state.teammembers.length;
    let selectedIndex = this.state.activeTeamMember + 1;
    selectedIndex = selectedIndex  % arr;

    this.setState ({
      show: true,
      activeTeamMember: selectedIndex,
      modalImage: this.state.teammembers[selectedIndex].popupimage,
      modalTitle: this.state.teammembers[selectedIndex].name,
      modalDescription: this.state.teammembers[selectedIndex].introduction
    })

  }

  handlePreviousTeamMember = () => {

    let arr = this.state.teammembers.length;
    let selectedIndex = this.state.activeTeamMember;

    if (selectedIndex === 0) {
      selectedIndex = arr - 1;
    } else {
      selectedIndex = selectedIndex - 1;
    }

    this.setState ({
      show: true,
      activeTeamMember: selectedIndex,
      modalImage: this.state.teammembers[selectedIndex].popupimage,
      modalTitle: this.state.teammembers[selectedIndex].name,
      modalDescription: this.state.teammembers[selectedIndex].introduction
    })

  }

  render() {
    const { teammembers, modalDescription, modalImage, modalTitle } = this.state;
    const carolina_data = teammembers.filter(function(member){
      return member.location.includes('Carolina') && member.image.length != 0
    });
    const california_data = teammembers.filter(function(member){
      return member.location.includes('California') && member.image.length != 0
    });
    return (
      <section className="team__wrapper">
        <div className="container">
          <h2 className="team__header-text"> Meet Your Instructors</h2>
          <h3>SOUTH CAROLINA</h3>
          <br/>
          <div className="team">
            {carolina_data.map((teammember, selectedIndex) => {
              return (
                <React.Fragment key={teammember.id}>
                  <TeamMember
                    name={teammember.name}
                    index={selectedIndex}
                    title={teammember.title}
                    image={teammember.image}
                    // introduction={teammember.introduction}
                    // onModalOpen={this.handleModalOpen}
                    // onModalClose={this.handleModalClose}
                    // onNext={this.handleNextTeamMember}
                    // onPrev={this.handlePreviousTeamMember}
                    // modalImage={modalImage}
                    // modalTitle={modalTitle}
                    // modalDescription={modalDescription}
                  />
                </React.Fragment>
              );
            })}
            </div>
            <br/>
            <h3>CALIFORNIA</h3>
            <br/>
            <div className="team">
            <br/>
            {california_data.map((teammember, selectedIndex) => {
              return (
                <React.Fragment key={teammember.id}>
                  <TeamMember
                    name={teammember.name}
                    index={selectedIndex}
                    title={teammember.title}
                    image={teammember.image}
                    // introduction={teammember.introduction}
                    // onModalOpen={this.handleModalOpen}
                    // onModalClose={this.handleModalClose}
                    // onNext={this.handleNextTeamMember}
                    // onPrev={this.handlePreviousTeamMember}
                    // modalImage={modalImage}
                    // modalTitle={modalTitle}
                    // modalDescription={modalDescription}
                  />
                </React.Fragment>
              );
            })}
          </div>
        </div>
      </section>
    );
  }
}

export default Team;
